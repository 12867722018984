//
// Custom radio
//

.custom-radio {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-checked-border-color;
        }

        &::after {
          background-image: $custom-radio-indicator-icon-checked;
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }
}
