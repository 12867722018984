@use 'sass:math';

$no-service-background-color : rgba(245, 245, 220, 0.5);
$precision : 24 * 12;

@for $startingIdx from 0 through $precision - 1 {
    .partial-service.start-#{$startingIdx}:before{
        content: "";
        left: 0;
        top: 0;
        width: (100 / $precision) * $startingIdx * 1%;
        height: 100%;
        position: absolute; 
        background: $no-service-background-color;
        z-index: 0;
    }
}
@for $endingIdx from 0 through $precision - 1 {
    .partial-service.end-#{$endingIdx}:after{
        content: "";
        right: 0;
        top: 0;
        width: (100 / $precision) * ($precision - $endingIdx) * 1%;
        height: 100%;
        position: absolute; 
        background: $no-service-background-color;
        z-index: 0;
    }
}

