.fc .confirmed {
}

.fc .to-create {
    border-width: medium !important;
    border-color: black !important;
    border-style: dashed !important;
    opacity: 0.8 !important;
}

.fc .to-delete {
    background: repeating-linear-gradient(45deg, black, black 4px, darkblue 4px, darkblue 12px);
}

.fc .to-modify {
    background-image: radial-gradient(black 20%, transparent 20%);
    background-position: 0 0, 5px 5px;
    background-size: 10px 10px;
}