/* -Navigation part- */
.navigation{
    width: 100%;
    height: 100%;
    .form-login{
        position: absolute;
        z-index: 2;
        top:50%;
        left: 50%;
        width: 600px;
        height: 300px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);;
        transform: translateX(-50%) translateY(-50%);
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
            -webkit-transition-delay: 9999s;
        }
        h1{
            margin-top: 80px;
            margin-bottom: 50px;
            text-align: center;
        }
        .login-btns-container{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            span.login-btns{
                height: 40px;
                line-height: 40px;
                padding-bottom: 40px;
                text-align: center;
                width: 50%;
                border-radius: 5px;
            }
            span.animated-button.thar-three {
                color: $primary;
                cursor: pointer;
                display: block;
                position: relative;
                border: 2px solid $primary;
                transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
            }
            span.animated-button.thar-three:hover {
                color: #fff !important;
                background-color: transparent;
                text-shadow: nthree;
            }
            span.animated-button.thar-three:hover:before {
                left: 0%;
                right: auto;
                width: 100%;
            }
            span.animated-button.thar-three:before {
                display: block;
                position: absolute;
                top: 0px;
                right: 0px;
                height: 100%;
                width: 0px;
                z-index: -1;
                content: '';
                color: #fff !important;
                background: $primary;
                transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
            }
        }
    }
    .navigation-left{
        height: 100%;
        width: 50%;
        float: left;
        position: fixed;
        z-index: 1;
        background-color: #f78c00;
        padding-left: 20px;
        padding-top: 20px;
        background-image: url("../img/brand/login_homepage.jpg");
        background-position: 10% 0;
        background-size: cover;
        img{
            width: 50%;
            -webkit-transform: translate(-70%,-50%);
            transform: translate(-70%,-50%);
            position: absolute;
            top: 50%;
            left: 31%;
        }
        .optaLabel{
            position: absolute;
            display: block;
            bottom: 50px;
            h2{
                margin-bottom: 10px;
            }
            h3{
                margin-top: 10px;
            }
            .divider{
                width: 40%;
                border-bottom: 2px solid #fff;
            }
        }
    }
    .navigation-right{
        position: relative;
        z-index: 1;
        float: right;
        width: 50%;
        height: max-content;
        background: #fff;
        min-height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        img.opta_logo{
            position: absolute;
            bottom: 50px;
            right: 20px;
        }
    }
    .formForgotPass{
        height:50vh;
        h1{
            margin-top: 80px;
        }
    }
}
@media only screen and (max-width: 896px) {
    .navigation{
        .navigation-left{
            width: 100%;
            height: 100%;
            padding-top: 15px;
            padding-bottom: 15px;
            .company-logo{
                width: 100%;
                height: 170px;
                margin-top: 0;
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
        .form-login{
            min-width: 50%;
            max-width: 60%;
        }
    }
}

@media only screen and (max-width: 540px) {
    .navigation{
        .form-login{
            min-width: 90% !important;
            max-width: 90% !important;
        }
        .navigation-right{
            width: 0%;
        }
    }
}


.label{
    font-size: 13px;
    background: rgba($color: #2f3542, $alpha: .2);
    color:#2f3542;
    width: max-content;
    border-radius: 5px;
    padding: 2px 5px;
    margin:3px 5px;
    white-space: nowrap;
    font-weight: 500;
    cursor: pointer !important;
}  

.label.label-lg{
    border-radius:5px;
    padding: 5px 13px;
}

@each $color, $value in $colors {
    .label.label-#{$color}{color:$value; background: transparentize($value, .82);}
}

.inputs-range-container{
    margin: 0 !important;
    .date-range-input{
        background: none !important;
        color: #435f71;
        border: none !important;
    }
}

.flat-btn {
    background: none !important;
    border: none;
    box-shadow: none !important;
    color: #435f71;
    margin-right: 0 !important;
}

.bordered-right {
    position: relative;
    &:after{
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        height: 70%;
        width: 1px;
        background: #435f71;
    }
}

.actions-btns{
    background: none !important;
    border: none;
    box-shadow: none !important;
    color: #435f71;
    position: relative;
}

.protocol-item-main{
    position: relative;
    display: inline;
    cursor: pointer;
    .protocol-day-item{
        padding: 4px 10px;
        background: transparentize($color: #2F80ED, $amount: .8);
        color: #2F80ED;
        font-weight: 600;
        border-right: 2px solid #fff;
        position: relative;
        font-size: 14px;
        &.first-item{
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
        &.last-item{
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
        }
        &.concat-item{
            background: transparentize($color: #435f71, $amount: .8);
            color: #435f71;
        }
    }
    .protocol-tooltip{
        display: none;
        position: absolute;
        bottom: 170%;
        left: 50%;
        width: max-content;
        transform: translateX(-50%);
        background: #435f71;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-style: italic;
        z-index: 10000;
        span{
            display: block;
        }
        &:after {
            content:'';
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 10px;
            height: 0;
            border-top: 12px solid #435f71;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
    }
    &:hover{
        .protocol-tooltip{
            display: block;
            
        }
    }
}

.nav-tabs{
    margin-top: 10px;
    .nav-link{
        cursor: pointer;
        &.active{
            color: #2F80ED !important;
        }
    }
}

.timeline-header-custom{
    background: #F2F7FA !important;
    span{
        color: #435F71 !important;
    }
    .rct-dateHeader{
        background: #F2F7FA !important;
    }
}


.horizon-date-container{
    display: flex;
    align-items: center;
    color: #435F71 !important;
    padding-bottom: 10px;
    span.action-arrow{
        cursor: pointer;
        i{
            font-size: 20px;
            display: flex;
            border-radius: 3px;
            padding: 0 3px;
            transition: .2s background ease-in-out;
            &:first-of-type{
                margin-right: 10px;
            }
            &:hover{
                background: rgba(0,0,0,.08);
            }
            &:active{
                color: #3498db;
            }
        }
    }
    span.week-number{
        background: #bccde0;
        color: #435F71;
        padding: 1px 5px;
        border-radius: 3px;
    }
    h4{
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        margin: 0 !important;
        color: #435F71 !important;
    }
}

.prescription-table{
    width: 100%;
    thead{
        height: 50px;
        background: #e6e6e6;
        th{
            text-align: center;
        }
    }
    tbody{
        tr{
            height: 50px;
            td{
                text-align: center;
                border-bottom: 1px solid #e1e1e1;
                input[type=number]{
                    width: 80px !important;
                    background: #efefef !important;
                    border: none;
                    height: 30px;
                    padding-left: 5px;
                }
            }
        }
    }
    .item{
        border-radius: 5px;
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #e6e6e6;
        margin-top: 10px;
        input[type=number]{
            width: 80px !important;
            background: #fff !important;
            border: none;
            height: 30px;
        }
        .protocol-input-container{
            display: flex;
            flex-direction: column;
            width: max-content;
            align-items: center;
        }
        i{
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .item-add{
        border: 3px dashed #aeaeae;
    }
}

#planning_view_mode{
    display: flex;
    justify-content: flex-end;
    align-items:center;
    #view_mode_text{
        margin-right: 5px;
        display: block;
    }
}
span.btn_mode{
    margin-right: 5px;
    padding: 4px 10px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #2F80ED;
    border-radius: 5px;
    &:first-of-type{
        margin-left: 5px;
    }
    &:hover{
        background: rgba(47, 128, 237,.2);
    }
    &.active{
        background: #2F80ED;
        color: #fff;
    }
}

/* -- FullCalendar-- */

.fc-timeline-header, .fc-scrollgrid-section-header, .fc-timeline-header-row  {
    background: #F2F7FA !important;
}
.fc-scrollgrid-section:nth-child(1) > :nth-child(1){
    background: #F2F7FA;
}
.resource-lane-stats{
    background: #F2F7FA !important;
}

.selected-lane{
    background: #ff9f43 !important;
    opacity: .15;
}

.fc-timeline-event{
    // overflow: hidden;
    // padding: 0 !important;
    height: 100% !important;
    .fc-event-main{
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.fc-col-header tbody{
background: #F2F7FA !important;
.fc-col-header-cell{
    border-bottom: 1px solid #ddd !important;
}
}


.fc-event-highlight{
background: $blue !important;
}

#parameters_select_container{
width: 90%;
margin: auto;
padding-top: 10px;
.RSelect__control{
    background: #e6e6e6;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 2px solid #435F71 !important;
}
} 

//FullCalendar Custom buttons in events
.event-actions-container{
position: absolute;
top: 5px;
right: 10px;
display: flex;
flex-direction: column;
i{
    font-size: 16px;
    padding: 2px;
    border-radius: 2px;
    &:hover{
        background: rgba(0,0,0,.15);
    }
}
}  

.fc_resoures_planning{
.fc-resource{
    height: 50px !important;
}
.fc-timeline-lane-frame{
    height: 100% !important;
}
.fc-timeline-event-harness{
    height: 80pxz;
}
&.fc_resoures_planning_month_view{
    .fc-timeline-slot-cushion {
        width: 100%;
    }
}
}

    /* DayPicker styles */

    .DayPicker {
        display: inline-block;
        font-size: 1rem;
        width: 100%;
      }
      
      
      .DayPicker-wrapper {
        position: relative;
        width: max-content;
        margin: 0 auto;
        flex-direction: row;
        padding-bottom: 1em;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }
      
      .DayPicker--interactionDisabled .DayPicker-Day {
        cursor: default;
      }
    
      .DayPicker-Weekday{
            width: 35px;
      }
    
      .DayPicker-Day{
        font-size: .8rem;
        width: 30px;
        height: 35px;
      }
      
      .DayPicker-Footer {
        padding-top: 0.5em;
      }
      
      .DayPicker-TodayButton {
        border: none;
        background-color: transparent;
        background-image: none;
        box-shadow: none;
        color: $primary !important;
        text-decoration:  underline;
        padding: 8px 12px;
        border-radius: 5px;
        display: block;
        margin: auto;
        font-size: 0.875em;
        cursor: pointer;
        transition: .3s;
      }
    
      .DayPicker-TodayButton:hover{
        background-color:$primary !important;
        color:#fff !important;
      }
    
      .DayPicker-Caption > div{
          font-weight: 400 !important;
          text-transform: capitalize;
      }
      


.fc-license-message{
    display: none;
}

// React calendar timeline
.react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important; // Prevent scrolling by touchpad
}

.rct-item{
    z-index: 7 !important;
}

.react-calendar-timeline .rct-calendar-header{
    z-index: 9 !important;
}