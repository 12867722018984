@import '../../../node_modules/material-design-icons/iconfont/material-icons.css';

.dropdown-menu-arrow.dropdown-menu.dropdown-menu-right{
    top: 100% !important;
}

.react-bootstrap-table {
    overflow-y: auto;
}

.react-bootstrap-table .table th, .react-bootstrap-table .table td {
    white-space: unset;
    word-wrap: initial;
}

.highcharts-fixed {
    display: none;
}

.highcharts-inner-container .highcharts-container {
    overflow-y: auto !important;
}

.highcharts-tooltip-container {
    z-index: 9999;
}

table.status-tracker {
    /*table-layout: fixed;*/
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

table.status-tracker td, table.status-tracker th {
    white-space: normal !important;
    word-wrap: break-spaces;
    padding: 0.5rem !important;
    text-align: center;
}

table.status-tracker th {
    vertical-align: middle;
}

table.status-tracker thead th.status:hover {
    background-color: #bafcff;
    cursor: pointer;
}

table.status-tracker .actual-status {
    background-color: #00ff6c;
}

.main-content .header {
    z-index: 1022 !important;
}

.compact-form > .form-group {
    margin-bottom: 0.75rem !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: baseline !important;
}

.compact-form > .form-group > * {
    margin-right: 0.5rem !important;
}

.compact-form > .form-group > *:last-child {
    margin-right: 0 !important;
}

/* .navbar-brand-img{
    position: absolute;
    bottom: 40px;
    left:45px;
} */

.labelFilterJobs{
    margin-bottom: 0 !important;
}

.card-header h2{
    margin-bottom: auto !important;
    margin-top: auto !important;
    margin-right: auto !important;
}

.fc-event-container{
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .navbar-brand-img {
        display: none !important;
    }

    .toggleSidebarBtn{
        display: none;
    }

    .main-content{
        margin: 0 !important;
    }

    .row{
        margin: 1rem 0 !important;
    }

    .fc-toolbar{
        display: block !important;
        text-align: center;
    }
    .fc-toolbar .fc-center{
        margin-bottom: 10px !important;
    }
}

.form-field-required::after {
    content: "*";
    color: red;
}

.modal-content ul {
    margin-bottom: 0;
    padding-left: 20px;
}

.icons-container{
    min-width: 75px;
    display: flex;
    justify-content: flex-end;
}

.icons-container svg {
    cursor: pointer;
    margin-right: 2px;
}