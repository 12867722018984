//
// Header
//

.header {
  position: relative;
  background: #e9f0f2;
  border-bottom: 1px solid rgb(209, 215, 235);
  height: 60px;
  a, i{
    color: #435f71 !important;
  }
}
