//
// Vertical navbar
//


// Vertical
//
// Creates a vertically aligned version of the navbar
.navbar-vertical {
  box-shadow: $navbar-vertical-box-shadow;
  background-color: #2F80ED !important;
  z-index: 999;
  .navbar {
    border-width: 0 0 1px 0;
    border-style: solid;
  }

  .nav-item, .nav-link{
    color: #fff !important;
  }



  // Navbar brand

  .navbar-brand {
    margin-right: 0;
    margin-top: 10px;
  }

  .navbar-brand-img,
  .navbar-brand > img {
    max-width: 100%;
    max-height: 2rem;
  }

  .navbar-nav {
    margin-left: -$navbar-padding-x;
    margin-right: -$navbar-padding-x;


    // Navbar link

    .nav-link {
      padding-left: $navbar-padding-x;
      padding-right: $navbar-padding-x;
      font-size: $navbar-nav-link-font-size;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0.25rem;
        bottom: 0.25rem;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        width: 90%;
        border-radius: 2px;
        z-index: -1;
      }
      &.active{
        &:before{
          background: rgba(255,255,255,.32) !important;
        }
      }
      &:hover{
        &:before{
          background: rgba(255,255,255,.2);
        }
      }

      // Icon

      > i {
        width: 50px;
        height: 40px;
        display: block;
        text-align: center;
        line-height: 2.5rem;
      }


      // Dropdown

      .dropdown-menu {
        border: none;

        .dropdown-menu {
          margin-left: $dropdown-item-padding-x / 2;
        }
      }
    }

  }

  &.navbar-small{
    .nav-link{
      justify-content: center;
      position: relative;
      span{
        display: none !important;
      }
      &:before {
        width: 70%;
      } 
      &::after{
        content: attr(data-title);
        width: max-content;
        background: #fff;
        box-shadow:
        0 0px 3.4px rgba(0, 0, 0, 0.051),
        0 0px 12.9px rgba(0, 0, 0, 0.081),
        0 0px 40px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        padding: .3rem .7rem;
        color: var(--gray-medium);
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all .1s ease-in-out;
        left: 110%;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-50%) scale(0);
        color: #435f71;
      }
      &:hover{
          &::after{
            visibility: visible;
            opacity: 1;
            transform: translateY(-50%) scale(1);
          }
      }
    }
  }


  // Navbar navigation
  .navbar-nav .nav-link {
    display: flex;
    align-items: center;
  }

  .navbar-nav .nav-link[data-toggle="collapse"] {
    &:after {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
      content: "\f105";
      margin-left: auto;
      color: $text-muted;
      transition: $transition-base;
    }

    // Expanded
    &[aria-expanded="true"] {

      &:after {
        transform: rotate(90deg);
      }
    }
  }

  // Second level
  .navbar-nav .nav .nav-link {
    padding-left: $navbar-padding-x + $navbar-icon-min-width;
  }

  // Third level
  .navbar-nav .nav .nav .nav-link {
    padding-left: $navbar-padding-x * 1.5 + $navbar-icon-min-width;
  }


  // Navbar heading
  .navbar-heading {
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    font-size: $font-size-xs;
    text-transform: uppercase;
    letter-spacing: .04em;
  }


  // Expanded navbar specific styles
  &.navbar-expand {
    @each $breakpoint,
    $dimension in $grid-breakpoints {

      &-#{$breakpoint} {
        @include media-breakpoint-up(#{$breakpoint}) {
          display: block;
          position: fixed;
          top: 0;
          bottom: 0;
          width: 100%;
          max-width: $navbar-vertical-width;
          padding-left: $navbar-vertical-padding-x;
          padding-right: $navbar-vertical-padding-x;
          // Container
          > [class*="container"] {
            flex-direction: column;
            align-items: stretch;
            min-height: 100%;
            padding-left: 0;
            padding-right: 0; // Target IE 10 & 11
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              min-height: none;
              height: 100%;
            }
          }


          // Fixes the vertical navbar to the left
          &.fixed-left {
            left: 0;
            border-width: 0 1px 0 0;
          }


          // Fixed the vertical navbar to the right
          &.fixed-right {
            right: 0;
            border-width: 0 0 0 1px;
          }


          // Navbar collapse
          .navbar-collapse {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-left: -$navbar-vertical-padding-x;
            margin-right: -$navbar-vertical-padding-x;
            padding-left: $navbar-vertical-padding-x;
            padding-right: $navbar-vertical-padding-x;

            > * {
              min-width: 100%;
            }
          }


          // Navbar navigation
          .navbar-nav {
            flex-direction: column;
            margin-left: -$navbar-vertical-padding-x;
            margin-right: -$navbar-vertical-padding-x;
          }


          // Second level
          .navbar-nav .nav .nav-link {
            padding-left: $navbar-vertical-padding-x + $navbar-icon-min-width;
          }


          // Third level
          .navbar-nav .nav .nav .nav-link {
            padding-left: $navbar-vertical-padding-x * 1.5 + $navbar-icon-min-width;
          }

          // Navbar brand
          .navbar-brand {
            display: block;
            text-align: center;
            padding-top: (2rem - $navbar-padding-y);
            padding-bottom: (2rem - $navbar-padding-y);
          }

          .navbar-brand-img {
            max-height: 2.5rem;
          }

          // Navbar user
          .navbar-user {
            margin-left: -$navbar-vertical-padding-x;
            margin-right: -$navbar-vertical-padding-x;
            padding-top: $spacer;
            padding-bottom: $spacer - $navbar-padding-y;
            padding-left: $navbar-vertical-padding-x;
            padding-right: $navbar-vertical-padding-x;
            border-top: 1px solid $border-color;

            // Dropup menu
            .dropup .dropdown-menu {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}
