//
// Dismissible alert
//

.alert-dismissible {
  .close {
    top: 50%;
    right: $alert-padding-x;
    padding: 0;
    transform: translateY(-50%);
    color: rgba($white, .6);
    opacity: 1;

    &:hover,
    &:focus {
      color: rgba($white, .9);
      opacity: 1 !important;
    }

    @include media-breakpoint-down(xs) {
      top: 1rem;
      right: .5rem;
    }

    & > span:not(.sr-only) {
      font-size: 1.5rem;
      background-color: transparent;
      color: rgba($white, .6);
    }

    &:hover,
    &:focus {
      & > span:not(.sr-only) {
        background-color: transparent;
        color: rgba($white, .9);
      }
    }
  }
}
