//
// Opacity
// modify the transparency of an element with this quick modifier classes
//

.opacity-1 {
  opacity: .1 !important;
}

.opacity-2 {
  opacity: .2 !important;
}

.opacity-3 {
  opacity: .3 !important;
}

.opacity-4 {
  opacity: .4 !important;
}

.opacity-5 {
  opacity: .5 !important;
}

.opacity-6 {
  opacity: .6 !important;
}

.opacity-7 {
  opacity: .7 !important;
}

.opacity-8 {
  opacity: .8 !important;
}

.opacity-8 {
  opacity: .9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}
