.rendezvous {
    overflow: visible;
    overflow-wrap: unset;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rendezvous .rendezvous-label {
    align-self: center;
    line-height: initial;
    z-index: 99;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: clip;
    text-wrap: wrap;
    max-height: 90%;
    text-align: center;
    font-stretch: extra-condensed;
}

.no-service {
    background: rgb(245, 245, 220, 0.5);
}

.public-holiday {
    background: rgba(255, 52, 16, 0.3) !important;
}

.ready-service {

}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0;
    color: #000;
}

.rendezvous.to-create {
    border-width: thick !important;
    border-style: solid !important;
    opacity: 0.7 !important;
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes blinking {
    50% {
        border-color: rgba(0, 255, 55, 0.5);
    }
}

.rendezvous.to-delete {
    /*background: repeating-linear-gradient(45deg, black, black 4px, darkblue 4px, darkblue 12px) !important;*/
}

.rendezvous.to-modify {
    background-image: radial-gradient(black 20%, transparent 20%) !important;
    background-position: 0 0, 5px 5px !important;
    background-size: 10px 10px !important;
    border-width: thick !important;
    border-style: solid !important;
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.working-hour-marker-end {
    width: 20px !important;
    background: repeating-linear-gradient(45deg,
            transparent,
            transparent 10px,
            #dbdbdb 10px,
            #dbdbdb 20px) !important;
    border-left: 3px solid #a09f9f !important;
}

.working-hour-marker-start {
    width: 20px !important;
    transform: translateX(-20px) !important;
    background: repeating-linear-gradient(45deg,
            transparent,
            transparent 10px,
            #dbdbdb 10px,
            #dbdbdb 20px) !important;
    border-right: 3px solid #a09f9f !important;
}